<template>
  <!-- 牙齿颜色下拉列表 -->
  <el-select
    v-model="val"
    size="mini"
    @change="onChange"
    filterable
    clearable
    :placeholder="lang_data == 1 ? '请选择' : 'Please select'"
  >
    <el-option
      value=""
      :label="lang_data == 1 ? '全部' : 'All'"
      v-if="isAll"
    ></el-option>
    <el-option
      v-for="item in list"
      :value="item.id"
      :label="item.color"
      :key="item.id"
    ></el-option>
    <el-option
      :value="-1"
      :label="lang_data == 1 ? '其他' : 'Other'"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  props: {
    factoryId: {
      type: [Number, String],
    },
    value: {
      type: [Number, String],
    },
    isAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      val: "", //
      list: [],
    };
  },
  mounted() {
    this.val = this.value;
    this.getList("");
  },
  methods: {
    onChange(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    },
    getList() {
      if (!this.factoryId) {
        return;
      }
      let params = {
        factory_id: this.factoryId,
        status: 1,
      };
      this.$axios.post("/setting/factory/getColorList", params).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  watch: {
    factoryId() {
      this.getList();
    },
    value(v, ov) {
      if (v != ov) {
        this.val = this.value;
      }
    },
  },
};
</script>
